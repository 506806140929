import * as THREE from 'three'
import { useEffect, useRef, useState, Suspense } from 'react'
import { Canvas, extend, useFrame } from '@react-three/fiber'
import {
  useCursor,
  MeshReflectorMaterial,
  Image,
  Text,
  Environment,
  useGLTF,
  useAnimations,
  useVideoTexture,
  useTexture,
  useAspect,
  Effects
} from '@react-three/drei'
import { useRoute, useLocation } from 'wouter'
import { easing } from 'maath'
import getUuid from 'uuid-by-string'
import { randInt } from 'three/src/math/MathUtils'
import useStore from './store'
import gsap from 'gsap'
import { UnrealBloomPass } from 'three-stdlib'
import {
  ChromaticAberration,
  EffectComposer,
  Noise
} from "@react-three/postprocessing";
import { BlendFunction } from "postprocessing";

import { useControls } from "leva";

const GOLDENRATIO = 1.61803398875
let index = 0
let index2 = 1
let canBeatHappen = true
let colorChangeDelay = 2500 // Delay in milliseconds between color changes

extend({ UnrealBloomPass })
function Bust() {
  const ref = useRef()
  const time = useRef(0)
  const { scene, animations, materials } = useGLTF('/bust.glb')
  const { actions, mixer } = useAnimations(animations, ref)
  const { drums } = useStore((state) => state.audio)
  const track = useStore((state) => state.track)
  const prevIntegerTime = useRef(0)
  const pointLight = useRef()
  const pointLight2 = useRef()
  const pointLight3 = useRef()
  const lastColorChangeTime = useRef(0)

  // Play all actions (the fragments flying off)
  useEffect(() => {
    Object.keys(actions).forEach((key) => {
      actions[key].play()
    })
  }, [])

  // Control the exploding statue and animate the point light
  useFrame((_, delta) => {
    const currentTime = Math.floor(time.current)
    const beatEvent = new CustomEvent('beat', {
      detail: { index: index, newUrl: `/image_${index2 + 1}.png`, newVideoUrl: `/videos/${index2 + 1}.webm` }
    })
    if (currentTime !== prevIntegerTime.current && canBeatHappen) {
      window.dispatchEvent(beatEvent)
      prevIntegerTime.current = currentTime
      console.log(index, 'beatindex')
      canBeatHappen = false
      index += 1
      index2 += 1
      if (index == 9) {
        index = 0 // Reset the index to 0 when it reaches 9
      }
      if (index2 == 90) {
        index2 = 0 // Reset the index to 0 when it reaches 9
      }
      setTimeout(() => {
        canBeatHappen = true
      }, colorChangeDelay)
    }
    mixer.timeScale = 1 // 22
    mixer.setTime((time.current = THREE.MathUtils.lerp(time.current, track.kicks * 1.25, track.kicks === 0 ? 0.25 : 0.15)))
    materials.inner.color.copy(red).multiplyScalar((drums.avg * drums.gain) / 30)
    // Animate the point light's color and brightness based on drums data
    const pointLightIntensity = (drums.avg * drums.gain) / 5000

    // Define an array of colors
    let colors = [
      new THREE.Color(50, 50, 50), // Dark Gray
      new THREE.Color(25, 25, 112), // Midnight Blue
      new THREE.Color(139, 0, 0), // Dark Red
      new THREE.Color(0, 100, 0), // Dark Green
      new THREE.Color(128, 0, 128), // Dark Purple
      new THREE.Color(139, 69, 19), // Saddle Brown
      new THREE.Color(47, 79, 79), // Dark Slate Gray
      new THREE.Color(128, 128, 0), // Olive
      new THREE.Color(139, 0, 139), // Dark Magenta
      new THREE.Color(0, 128, 128), // Teal
    ];
    let colors2 = [
      new THREE.Color(255, 0, 255), // Magenta
      new THREE.Color(0, 255, 255), // Cyan
      new THREE.Color(255, 215, 0), // Gold
      new THREE.Color(255, 20, 147), // Deep Pink
      new THREE.Color(0, 255, 0), // Lime
      new THREE.Color(255, 165, 0), // Orange
      new THREE.Color(255, 69, 0), // Red-Orange
      new THREE.Color(255, 140, 0), // Dark Orange
      new THREE.Color(255, 105, 180), // Hot Pink
      new THREE.Color(255, 69, 0), // Red-Orange
    ];
    let colors3 = [
      new THREE.Color(255, 20, 147), // Deep Pink
      new THREE.Color(0, 255, 0), // Lime
      new THREE.Color(255, 165, 0), // Orange
      new THREE.Color(255, 69, 0), // Red-Orange
      new THREE.Color(255, 140, 0), // Dark Orange
      new THREE.Color(255, 105, 180), // Hot Pink
      new THREE.Color(255, 69, 0), // Red-Orange
      new THREE.Color(255, 215, 0), // Gold
      new THREE.Color(255, 0, 255), // Magenta
      new THREE.Color(0, 255, 255), // Cyan
    ];

    // Animate the point lights with a delay between color changes
    const currentTimeMillis = new Date().getTime()
    pointLight.current.intensity = pointLightIntensity
    pointLight2.current.intensity = pointLightIntensity
    pointLight3.current.intensity = pointLightIntensity
    if (currentTimeMillis - lastColorChangeTime.current > colorChangeDelay) {
      pointLight.current.color.copy(colors[Math.floor(Math.random() * colors.length)])
      pointLight.current.position.x += Math.random() * 0.01 - 0.005
      pointLight.current.position.y += Math.random() * 0.01 - 0.005
      pointLight.current.position.z += Math.random() * 0.01 - 0.005

      pointLight2.current.color.copy(colors[Math.floor(Math.random() * colors.length)])
      pointLight2.current.position.x += Math.random() * 0.01 - 0.005
      pointLight2.current.position.y += Math.random() * 0.01 - 0.005
      pointLight2.current.position.z += Math.random() * 0.01 - 0.005

      pointLight3.current.color.copy(colors[Math.floor(Math.random() * colors.length)])
      pointLight3.current.position.x += Math.random() * 0.01 - 0.005
      pointLight3.current.position.y += Math.random() * 0.01 - 0.005
      pointLight3.current.position.z += Math.random() * 0.01 - 0.005

      lastColorChangeTime.current = currentTimeMillis
    }
  })

  return (
    <>
      <pointLight ref={pointLight} position={[-8, 0, 0.5]} intensity={0} object={scene} />
      <pointLight ref={pointLight2} position={[0, 0, -2.5]} intensity={0} object={scene} />
      <pointLight ref={pointLight3} position={[8, 0, 0.5]} intensity={0} object={scene} />
      <primitive scale={[0.05, 0.05, 0.05]} position={[0, -0.5, 4.5]} rotation={[0, -2.4, 0]} ref={ref} object={scene} />
    </>
  )
}
function Intro() {
  const clicked = useStore((state) => state.clicked)
  const api = useStore((state) => state.api)
  useEffect(() => api.loaded(), [])
  // Zoom in camera when user has pressed start
  return useFrame((state) => {
    if (clicked) {
      // state.camera.position.lerp(vec.set(-2 + state.mouse.x, 2, 4.5), 0.05)
      // state.camera.lookAt(0, 0, 0)
      setTimeout(() => {
        colorChangeDelay = 500;
      }, 5800);
    }
  })
}

const red = new THREE.Color('#900909')
export default function App({ images }) {
  // Event handler to update the image URL
  const [updatedImages, setUpdatedImages] = useState(images)
  const handleBeat = (e) => {
    const indexToUpdate = e.detail.index // Specify the index of the image to update
    const newImageUrl = e.detail.newUrl // Generate a new URL
    const updatedImagesCopy = [...updatedImages] // Create a copy of the images array
    updatedImagesCopy[indexToUpdate].url = newImageUrl // Update the URL at the specified index
    updatedImagesCopy[indexToUpdate].urlvideo = e.detail.newVideoUrl // Update the URL at the specified index
    updatedImages[e.detail.index].position = updatedImages[e.detail.index].positionfinal

    setUpdatedImages(updatedImagesCopy) // Update the state with the new image URLs
  }
  window.addEventListener('beat', handleBeat)
  const { offset } = useControls({
    offset: {
      value: [0.0002, 0.0002]
    }
  });
  return (
    <Canvas dpr={[1, 1.5]} camera={{ fov: 70, position: [0, 2, 15] }}>
    
      <Suspense fallback={null}>
      <EffectComposer>
          <ChromaticAberration offset={offset} />
          <Noise premultiply blendFunction={BlendFunction.ADD} />

        </EffectComposer>
        {/* <color attach="background" args={['#191920']} /> */}
        {/* <fog attach="fog" args={['#191920', 0, 15]} /> */}
        <color attach="background" args={['#000000']} />
        <fog attach="fog" args={['#000000', 0, 15]} />
        
        {/* <group position={[0, -0.5, 0]}> */}
        {/* Horizontal plane */}
        <mesh rotation={[1.5, 0, 0]} position={[-4, 1.5, 0]}>
          <planeGeometry args={[50, 50]} />
          <MeshReflectorMaterial
            blur={[300, 100]}
            resolution={2048}
            mixBlur={1}
            mixStrength={80}
            roughness={1}
            depthScale={1.2}
            minDepthThreshold={0.4}
            maxDepthThreshold={1.4}
            color="#050505"
            metalness={0.5}
          />
        </mesh>
        <group position={[0, -0.5, 0]}>
          <Frames images={updatedImages} />

          <mesh rotation={[-Math.PI / 2, 0, 0]}>
            <planeGeometry args={[50, 50]} />

            <MeshReflectorMaterial
              blur={[300, 100]}
              resolution={2048}
              mixBlur={1}
              mixStrength={80}
              roughness={1}
              depthScale={1.2}
              minDepthThreshold={0.4}
              maxDepthThreshold={1.4}
              color="#050505"
              metalness={0.5}
            />
          </mesh>
        </group>
        {/* <Environment preset="forest" /> */}
        <ambientLight intensity={1} />

        <Bust />
        <Intro />
      </Suspense>
    </Canvas>
  )
}

let isp = false
let lastr = 0
function Frames({ images, q = new THREE.Quaternion(), p = new THREE.Vector3() }) {
  const ref = useRef()
  const clicked = useRef()
  const [, params] = useRoute('/item/:id')
  const [, setLocation] = useLocation()

  useEffect(() => {
    clicked.current = ref.current.getObjectByName(params?.id)
    if (clicked.current) {
      clicked.current.parent.updateWorldMatrix(true, true)
      clicked.current.parent.localToWorld(p.set(0, GOLDENRATIO / 2, 1.25))
      clicked.current.parent.getWorldQuaternion(q)
    } else {
      p.set(0, 0, 5.5)
      q.identity()
    }
  })
  useFrame((state, dt) => {
    easing.damp3(state.camera.position, p, 0.4, dt)
    easing.dampQ(state.camera.quaternion, q, 0.4, dt)
  })

  const changeLocation = (e) => {
    e.stopPropagation(), setLocation(clicked.current === e.object ? '/' : '/item/' + e.object.name)
  }

  // setLocation('/item/36e84dac-ebaf-52ac-aef0-317deacb14ae')
  return (
    <group ref={ref} onClick={changeLocation} onPointerMissed={() => setLocation('/')}>
      {images.map((props, index) => (
        <Frame key={props.url} index={index} {...props} />
      ))}
    </group>
  )
}

function VideoMaterial({ url }) {
  const texture = useVideoTexture(url)
  return <meshBasicMaterial map={texture} toneMapped={false} />
}

function Frame({ url, c = new THREE.Color(), ...props }) {
  const image = useRef()
  const frame = useRef()
  const [, params] = useRoute('/item/:id')
  const [hovered, hover] = useState(false)
  const [rnd] = useState(() => Math.random())
  const name = getUuid(url)
  const isActive = params?.id === name

  useCursor(hovered)
  useFrame((state, dt) => {
    image.current.material.zoom = 2 + Math.sin(rnd * 10000 + state.clock.elapsedTime / 3) / 2
    image.current.material.needsUpdate = true

    easing.damp3(image.current.scale, [0.85 * (!isActive && hovered ? 0.85 : 1), 0.9 * (!isActive && hovered ? 0.905 : 1), 1], 0.1, dt)
    easing.dampC(frame.current.material.color, hovered ? 'orange' : 'white', 0.1, dt)
  })
  const size = useAspect(9, 9, 0.1)

  return (
    <group {...props}>
      <mesh
        name={name}
        onPointerOver={(e) => (e.stopPropagation(), hover(true))}
        onPointerOut={() => hover(false)}
        scale={[1, GOLDENRATIO, 0.05]}
        position={[0, GOLDENRATIO / 2, 0]}>
        <boxGeometry />
        <meshStandardMaterial color="#151515" metalness={0.5} roughness={0.5} envMapIntensity={2} />
        <mesh ref={frame} raycast={() => null} scale={[0.9, 0.93, 0.9]} position={[0, 0, 0.2]}>
          <boxGeometry />
          <meshBasicMaterial toneMapped={false} fog={false} />
        </mesh>
        {/* <mesh scale={size}   position={[0, 0, 1]}>
          <planeGeometry />
          <VideoMaterial url={props.urlvideo} />
        </mesh> */}

        <Image raycast={() => null} ref={image} position={[0, 0, 0.7]} url={url} />
      </mesh>
      <Text maxWidth={0.1} anchorX="left" anchorY="top" position={[0.55, GOLDENRATIO, 0]} fontSize={0.025}>
        {name.split('-').join(' ')}
      </Text>
    </group>
  )
}
