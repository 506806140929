import { createRoot } from 'react-dom/client'
import './styles.css'
import App from './App'
import useStore from './store'
const images = [
  // Front
  { position: [0, -2, -2], positionfinal: [0, 0, 1.5], rotation: [0, 0, 0], url: '/1.avif',urlvideo: "videos/1.mp4" },
  // Back
  { position: [0, -2, -2], positionfinal: [-0.8, 0, -0.6], rotation: [0, 0, 0], url: '/2.avif',urlvideo: "videos/1.mp4" },
  { position: [0, -2, -2], positionfinal: [0.8, 0, -0.6], rotation: [0, 0, 0], url: '/3.avif',urlvideo: "videos/1.mp4" },
  // Left
  { position: [0, -2, -2], positionfinal: [-1.75, 0, 0.25], rotation: [0, Math.PI / 2.5, 0], url: '/4.avif',urlvideo: "videos/1.mp4" },
  { position: [0, -2, -2], positionfinal: [-2.15, 0, 1.5], rotation: [0, Math.PI / 2.5, 0], url: '/5.avif',urlvideo: "videos/1.mp4" },
  { position: [0, -2, -2], positionfinal: [-2, 0, 2.75], rotation: [0, Math.PI / 2.5, 0], url: '/6.avif',urlvideo: "videos/1.mp4" },
  // Right
  { position: [0, -2, -2], positionfinal: [1.75, 0, 0.25], rotation: [0, -Math.PI / 2.5, 0], url: '/7.avif',urlvideo: "videos/1.mp4" },
  { position: [0, -2, -2], positionfinal: [2.15, 0, 1.5], rotation: [0, -Math.PI / 2.5, 0], url: '/8.avif',urlvideo: "videos/1.mp4" },
  { position: [0, -2, -2], positionfinal: [2, 0, 2.75], rotation: [0, -Math.PI / 2.5, 0], url: '/9.avif',urlvideo: "videos/1.mp4" }
]

function Overlay() {
  const loaded = useStore((state) => state.loaded)
  const clicked = useStore((state) => state.clicked)
  const api = useStore((state) => state.api)
  return (
    <>
      <div className={`fullscreen bg ${loaded ? 'loaded' : 'notready'} ${clicked && 'clicked'}`}>
        <button className='fullscreen-start' onClick={() => loaded && api.start()}>
          {!loaded ? (
            'loading'
          ) : (
            <>
              <span>click to continue</span>
            </>
          )}
        </button>
      </div>
    </>
  )
}
createRoot(document.querySelector('#root')).render(
  <>
    <Overlay />
    <App images={images} />
  </>
)
